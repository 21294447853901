import {post,get} from '@/utils/request.js'
import config from '@/utils/config'
// 查询区块详情
export function GetBlockbyNum(params,loading=true){
	return get(config.minerUrl+'/website/getLateBlockData',params,loading)
}
// 最新矿工交易
export function getLateTxData(params,loading=true){
	return get(config.minerUrl+'/website/getLateTxData',params,loading)
}
// 最新矿工交易
export function minerAddrInfo(params,loading=true){
	return get(config.minerUrl+'/website/minerAddrInfo',params,loading)
}
// 通用查询 地址，hash 区块 查询
export function queryData(params,loading=true){
	return get(config.minerUrl+'/website/queryData',params,loading)
}
// 查询数量
export function GetBalance(params,loading=true){
	return get(config.minerUrl+'/website/getBalance',params,loading)
}
//查区块详情
export function getTxByBlock(params,loading=true){
	return get(config.minerUrl+'/website/getTxByBlock',params,loading)
}
//查询搜索值类型
export function queryStr(params,loading=false){
	return get(config.minerUrl+'/website/queryStr',params,loading)
}
//查询合约信息
export function getContranctInfoApi(params,loading=false){
	return get(config.minerUrl+'/website/getContranctInfo',params,loading)
}
//查询合约交易 addr，contract，size，limit
export function getAddrContractTxApi(params,loading=false){
	return get(config.minerUrl+'/website/getAddrContractTx',params,loading)
}
//查询721合约交易 addr，size，limit
export function GetAddr721ContractTxApi(params,loading=false){
	return get(config.minerUrl+'/website/getAddr721ContractTx',params,loading)
}

//矿工列表
export function minerListApi(params,loading=false){
	return get(config.minerUrl+'/website/minerList',params,loading)
}
//查询矿工出块数据 addr，size，limit
export function getMinerTxDataApi(params,loading=false){
	return get(config.minerUrl+'/website/getMinerTxData',params,loading)
}
//矿工详细数据
export function minerAddrInfoApi(params,loading=false){
	return get(config.minerUrl+'/website/minerAddrInfo',params,loading)
}
//地址持币查询
export function getMaxAddrApi(params,loading=false){
	return get(config.minerUrl+'/website/getMaxAddr',params,loading)
}
//根据合约地址查询
export function getContranctCodeApi(params,loading=false){
	return get(config.minerUrl+'/website/getContranctCode',params,loading)
}
//上传合约代码
export function contractCodeApi(params,loading=false){
	return post(config.minerUrl+'/website/contractCode',params,loading)
}
//是否上传合约代码
export function isUploadContractApi(params,loading=false){
	return get(config.minerUrl+'/website/isContractAuth',params,loading)
}
//封装查询合约代码内容
export function getContractCodeApi(params,loading=false){
  console.log(config.minerUrl+'static/'+params)
	return get(config.minerUrl+'static/'+params,{},loading)
}
//根据合约地址查询持币地址
export function querySymbolAmountApi(params,loading=false){
	return get(config.minerUrl+'/website/querySymbolAmount',params,loading)
}







//GetUSDkto
export function GetUSDkto(params,loading=true){
	//return get('/api/GetUSDkto',params,loading)
	return post('https://www.bcone.vip/api/market/tickers/ticker?symbol=usdt_kto',params,loading)

}
// 通过地址查询所有交易
export function GetTxsbyAddr(params,loading=true){
	// return post('/api/GetTxsbyAddr',params,loading)
	return get(config.url1+'/GetTxsByAddr',params)
}
// 通过地址查询代币交易
export function GetTokenTxsByAddr(params,loading=true){
	return get(config.url1+'/GetTokenTxsByAddr',params)
}
// 获取块高列表
export function GetBlockbyNum_FY(params){
	return get(config.url+'/block',params)
	// return post('/api/GetBlockbyNum_FY',params)

}
// 最新区块获取交易列表
export function GetBlock_FY(params){
	return get('/api/GetBlock_FY',params)
}
// 交易详情
export function GetTxbyhash(params){
	return get(config.url3+'/GetTxsByHash',params)
	// return post('/api/GetTxbyhash',params)
}
// 获取详细交易精度
export function GetTokenDemic(params){
	// return post('/api/GetTokenDemic',params)
	return get(config.minerUrl+'/website/getTokenDec',params)

}
// 首页查询最新区块
export function GetMaxBlockNum(params,loading=true){
	return get(config.url+'/height',params,loading)
	// return post('/api/GetMaxBlockNum',params,loading)
}
//获取首页统计数据
export function GetTotalData(params,loading=true){
	return get(config.minerUrl+'/website/minerData',params,loading)
}

//defi
export function GetDeDi(params,loading=true){
	return post('https://www.bcone.vip/api/market/tickers/ticker?symbol=usdt_'+ params,params,loading)
}
//首页图表数据
export function Get24HData(params,loading=true){
	return get(config.url1+'/Get24HData',params,loading)
}

// 创建代币  登录
export function loginContractApi(params){
	return get(config.url2+'/login',params)
}
// 创建代币  注册
export function registContractApi(params){
	return get(config.url2+'/register',params)
}
// 创建代币  发送验证码
export function sendEmailApi(params){
	return get(config.url2+'/sendEmail',params)
}
// 创建代币  创建
export function creatContractApi(params){
	return get(config.url2+'/creatContract',params)
}
//代币总数
export function GetTotalToken(params,loading=true){
	return get(config.url1+'/GetTotalToken',params,loading)
}

// ---------2021-6-29 添加HTC-20列表，hash交易详情，查地址----------------
//通过用户地址获取交易（传入from或to地址）
export function Get20tokenTxsByAddr(params,loading=true){
	return get(config.url3+'/Get20tokenTxsByAddr',params,loading)
}
//通过合约地址获取合约数据
export function Get20tokenDataByContract(params,loading=true){
	return get(config.url3+'/Get20tokenDataByContract',params,loading)
}
//通过合约地址获取此合约交易数据
export function Get20tokenTxsByContract(params,loading=true){
	return get(config.url3+'/Get20tokenTxsByContract',params,loading)
}
//通过hash获取交易
export function Get20tokenTxByHash(params,loading=true){
	return get(config.url3+'/Get20tokenTxByHash',params,loading)
}
//通过hash获取交易
export function GetTxsByHash(params,loading=true){
	return get(config.url3+'/GetTxsByHash',params,loading)
}
// 获取最新10条合约交易
export function Get20tokenTxsLast_10(params,loading=true){
	return get(config.url3+'/Get20tokenTxsLast_10',params,loading)
}
// 地址详情
export function AddressDetails(params,loading=true){
	return get(config.url3+'/AddressDetails',params,loading)
}
// 根据三种地址查20交易
export function GetContractTxsByAddr(params,loading=true){
	return get(config.url3+'/GetContractTxsByAddr',params,loading)
}
// 获取合约code
export function GetCode(params,loading=true){
	return get(config.url3+'/GetCode',params,loading)
}
// 保存已验签的合约到后台
export function StoreVerifiedContract(params,loading=true){
	return post(config.url3+'/StoreVerifiedContract',params,loading)
}
// 获取已验签的合约
export function GetVerifiedContract(params,loading=true){
	return post(config.url3+'/GetVerifiedContract',params,loading)
}
// GetTxsByAddr_TokenName
export function GetTxsByAddr_TokenName(params,loading=true){
	return get(config.url3+'/GetTxsByAddr_TokenName',params,loading)
}
