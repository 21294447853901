export function IsPC() {
	var userAgentInfo = navigator.userAgent;
	var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
	var flag = true;
	for (var v = 0; v < Agents.length; v++) {
		if (userAgentInfo.indexOf(Agents[v]) > 0) {
			flag = false;
			break;
		}
	}
	return flag;
}
export function dateFormat(num) {
	num = parseInt(num);
	var Object = new Date((num * 1000));
	var Year = String(Object.getFullYear());
	var Month = String(Object.getMonth() + 1);
	if (Month.length < 2) Month = "0" + Month;
	var Day = String(Object.getDate());
	if (Day.length < 2) Day = "0" + Day;
	var Hour = String(Object.getHours());
	if (Hour.length < 2) Hour = "0" + Hour;
	var Minute = String(Object.getMinutes());
	if (Minute.length < 2) Minute = "0" + Minute;
	var Second = String(Object.getSeconds());
	if (Second.length < 2) Second = "0" + Second;
	// return(Year + '-' + Month + '-' + Day + ' ' + Hour + ':' + Minute)
	return (
		Year + "-" + Month + "-" + Day + " " + Hour + ":" + Minute + ":" + Second
	);
	// return(Year + '-' + Month + '-' + Day + ' ' + Hour + ':' + Minute)
}

export function BigNum(num) {
	// console.log(BigNumber)
	// new BigNumber(num)
}

export function timeFormat(hisTime) {
	var now = parseInt(new Date().getTime()/1000),
		diffValue = now - hisTime,
		result = '',
		sec = 1000 * 60 *60,
		minute = 1000 * 60,
		hour = minute * 60,
		day = hour * 24,
		halfamonth = day * 15,
		month = day * 30,
		year = month * 12,
		

		_year = diffValue / year,
		_month = diffValue / month,
		_week = diffValue / (7 * day),
		_day = diffValue / day,
		_hour = diffValue / hour,
		_min = diffValue / minute,
		_sec = diffValue / sec;
	
	if (_year >= 1) result = parseInt(_year) + "years ago";
	else if (_month >= 1) result = parseInt(_month) + "months ago";
	else if (_week >= 1) result = parseInt(_week) + "weeks ago";
	else if (_day >= 1) result = parseInt(_day) + "days ago";
	else if (_hour >= 1 && hour<24) result = parseInt(_hour) + "hour ago";
	else if (_min >= 1 && _min <60) result = parseInt(_min) + "minute ago";
	else if (_sec < 60&&_sec>=1) result = parseInt(_sec)+1 + "seconds ago";
	//else console.log('_sec',parseInt(_hour),parseInt(_min),parseInt(_sec))
	
	return result;
}


export function daibiWebSite(symbol){
	switch (symbol){
		case 'CM':
			return 'http://www.cmweb.io'
			break
		case 'CMC':
			return 'http://www.cmweb.io'
			break
		case 'OPS':
			return 'http://opsweb.cc'
			break
		case 'MKP':
			return 'http://opsweb.cc'
			break
		case 'FYFF':
			return 'http://www.fyff.io'
			break
		case 'FGTT':
			return 'http://www.fyff.io'
			break
		case 'BOFI':
			return 'http://bofi.pro'
			break
		case 'JPT':
			return 'http://jptweb.me'
			break
		case 'CSTT':
			return 'http://jptweb.me'
			break
		case 'FEC':
			return 'http://fecweb.xyz'
			break
		case 'FECC':
			return 'http://fecweb.xyz'
			break
		case 'MGTT':
			return 'http://www.mgttweb.xyz'
			break
		case 'TCB':
			return 'http://www.tcbtoken.pro'
			break
		case 'MODY':
			return 'https://www.mody.pro'
			break
		case 'SFCT':
			return 'https://www.mysfct.com'
			break
		default :
			return ''
	}
}