<template>
	<div id="app" ref="main">
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive"></router-view>
		</keep-alive>
		<router-view  v-if="!$route.meta.keepAlive"></router-view>
	</div>
</template>
<script>
	import {IsPC} from './utils/lib.js'
	import {GetUSDkto} from '@/api/home'
	import testData from '@/utils/testData'
	export default {
		mounted() {
			var that = this
			// 窗口发生变化的时候
			var IsPC = this.IsPC()
			that.$store.commit('SET_ISPC', IsPC)
			window.onresize = () => {
				return (() => {
					var IsPC = that.IsPC()
					that.$store.commit('SET_ISPC', IsPC)
				})()
			}
			this.getUSDkto()
		},
		watch:{
			$route(e){
				var main = this.$refs['main']
				main.scroll(0, 0);
			}
		},
		methods: {
			IsPC() {
				var userAgentInfo = navigator.userAgent;
				var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
				var flag = true;
				for (var v = 0; v < Agents.length; v++) {
					if (userAgentInfo.indexOf(Agents[v]) > 0) {
						flag = false;
						break;
					}
				}
				return flag;
			},
			//查询最新币价
			getUSDkto(){
				// GetUSDkto({},false).then(res=>{
				// 	if(res.success){
				// 		testData.newPrice = res.data.Last
				// 	}
				// })
			},
		},
		updated() {
			window.scroll(0, 0);
		}
	}
</script>

<style lang="scss">
body{
	background: #F8FAFF;
}
#app{
	height: 100%;
	overflow-y: auto;
}
</style>
