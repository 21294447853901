<template>
	<div class="main-Width home-main">
		<div class="home-block-title">
			<p>Latest Blocks</p>
		</div>
		<div>
			<el-table :data="blockListData" style="width: 100%"
			:header-cell-style="headerStyle" :row-style="rowStyle">
				<el-table-column fixed label="Block" min-width="140">
					<template slot-scope="scope">
            <p class="new-block">
              <img src="../../assets/img/quk.png" />
              <span  :class="{'gary-gujia':scope.row.gj}" class="click-item"
              	@click="scope.row.Block&&goBlockDetail(scope.row.Block)">{{ scope.row.Block }}</span>
            </p>

					</template>
				</el-table-column>
				
				<el-table-column label="Miner" align="center" min-width="220">
					<template slot-scope="scope">
						<span :class="{'gary-gujia':scope.row.gj}">{{ scope.row.MIne|addrStr(20) }}</span>
					</template>
					<template slot-scope="scope">
						<el-tooltip placement="bottom">
							<div slot="content" style="width: 150px;">{{scope.row.MIne}}</div>
							<span style="white-space: nowrap;overflow:hidden" :class="{'gary-gujia':scope.row.gj}"
							@click="goTransferDetail(scope.row.MIne)">{{ scope.row.MIne|addrStr(20) }}</span>
						</el-tooltip>
					</template>
				</el-table-column>
				<!-- <el-table-column label="Gas Used" align="center" min-width="150">
					<template slot-scope="scope">
						<span :class="{'gary-gujia':scope.row.gj}">{{scope.row.TotalGas}}</span>
					</template>
				</el-table-column> -->
				<el-table-column label="Block Reward" align="center" min-width="160">
					<template slot-scope="scope">
						<span :class="{'gary-gujia':scope.row.gj}">{{scope.row.Reward}} KTO</span>
					</template>
				</el-table-column>
        <el-table-column label="Txn" align="center">
        	<template slot-scope="scope">
        		<span :class="{'gary-gujia':scope.row.gj}">{{ scope.row.Count}}</span>
        	</template>
        </el-table-column>
				<el-table-column label="Total Value" align="center" min-width="180">
					<template slot-scope="scope">
						<span :class="{'gary-gujia':scope.row.gj}">{{scope.row.TotalAmount}} KTO</span>
					</template>
				</el-table-column>
        <el-table-column label="Age" align="center" min-width="180">
        	<template slot-scope="scope">
        		<span :class="{'gary-gujia':scope.row.gj}">{{ scope.row.CreateTime}}</span>
        	</template>
        </el-table-column>
			</el-table>
		</div>
		<div class="table-body show-more" @click="showMore()" v-if="show">
			<span>Show More</span>
			<img src="../../assets/img/more.png" />
		</div>
	</div>
</template>

<script>
	import {dateFormat,timeFormat} from '@/utils/lib.js'
	import testData from '@/utils/testData'
	import {GetBlockbyNum} from '@/api/home'
	export default{
		data(){
			return {
				newBlock:testData.newBlock,//�新区�
				blockList:[],
				blockListData:[],
				zancun:[],
				show:true,
				headerStyle:{
					color:'#7E8499',
					'font-weight':'100',
					background:'#F8FAFD',
					padding:'6px 10px',
					'border-top':'1px solid #E7EAF3',
					'line-height':1
				},
				rowStyle:{
					padding:'0px 10px',
					'font-size':'13px',
					'font-family':'numRegularFont',
					color:'#7E8499',
					'line-height':1
				}
			}
		},
		created() {
			this.getBlocak()
		},
		methods:{
			// ��������
			goBlockDetail(item) {
				this.$store.commit('SET_NAVINDEX', 1)
				this.$router.push({
					path: '/Bd',
					query: {
						searchVal: item
					}
				})
			},
			getBlocak(){
				GetBlockbyNum({page:1,size:10},false).then(data=>{
					this.blockList = data.data
					this.blockListData=this.blockList.slice(0,10)
				})
			},
			showMore(){
				this.blockListData=this.blockList
				this.show=false
			},
		},
		filters:{
			timeStr(n){
				return dateFormat(n)
			},
			addrStr(str,n){
				if(str){
					return str.substring(0,n?n:14)+'...'
				}else{
					return str
				}

			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.rowclass{
		color: #c20919;
	}
  .new-block{
    display: flex;
    align-items: center;
    margin-right: 5px;
    img{
      width: 25px;
      height: 25px;
      margin-right: 5px;
    }
  }
	.home-main{
		border: 1px solid #E7EAF3;
		margin-top: 20px;
		border-radius: 10px;
		box-sizing: border-box;
		box-shadow: 0px 6px 12px rgba(158, 158, 161, 0.12);
		background: #FFFFFF;
		.home-block-title{
			padding-left: 20px;
			height: 50px;
			display: flex;
			justify-content: center;
			flex-direction: column;
			p{
				color: #2A2C33;
				font-size: 16px;
				font-family: 'syMediumFont';
			}
		}
		.show-more{
			display: flex;
			justify-content: center!important;
			cursor: pointer;
			img{
				display: block;
				margin-top: 4px;
				margin-left: 2px;
			}
			span{
				font-size: 14px;
				color: #121d37;
			}
		}
		.table-header{
			padding: 0 20px;
			height: 36px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: #F8FAFD;
			border-top: 1px solid #E7EAF3;
			color: #7E8499;
			box-sizing: border-box;
			font-size: 14px;
			.table-header-x{
				width: 180px;
				text-align: left;
			}
			.table-header-n{
				width: 130px;
				text-align: center;
			}
			.table-header-t{
				width: 180px;
				text-align: center;
			}
			.table-header-h{
				width: 100px;
				text-align: left;
			}
		}
		.center{
			text-align: center!important;
		}
		.table-body{
			padding: 0 20px;
			height: 46px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			// border-top: 1px solid #E7EAF3;
			color: #7E8499;
			box-sizing: border-box;
			font-family:'numRegularFont';
			.table-body-x{
				width: 180px;
				text-align: left;
				cursor: pointer;
			}
			.table-body-n{
				width: 130px;
				text-align: center;
			}
			.table-body-t{
				width: 180px;
				text-align: center;
			}
			.table-body-h{
				width: 100px;
				text-align: left;
				color: #1A89FA;
				cursor: pointer;
			}
		}
	}
</style>
