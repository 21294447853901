<template>
	<div class="home-footer" :style="{'margin-top': marginTop}">
    <img src="../assets/img/h-bj.png" class="f-bj" />
    <div class="footer-content">
      <div class="main-Width footer-box">
      	<div class="footer-item left-logo">
			<div class="ff-logo">
				<img src="../assets/img/ff-logo.png" />
				<span> KorthoChain </span>
			</div>
      		
      		<p>Business:Business@sadaac.com</p>
      		<p>Contactus:korthochain@gmail.com</p>
      	</div>
      	<div class="footer-item contact-ico">
      		<h1>Social Network</h1>
      		<a href="https://www.facebook.com/rtho.ko" target="_blank">Facebook</a>
      		<a href="https://twitter.com/kortho2" target="_blank">Twitter</a>
      		<a href="https://t.me/KorthoChain" target="_blank">Telegram</a>
      		<a href="https://medium.com/@korthochain" target="_blank">Medium</a>
      	</div>
      	<div class="footer-item">
      		<h1>Products</h1>
      		<a href="https://github.com/kortho0/kortho" target="_blank">GitHub</a>
      	</div>
      	<div class="footer-item">
      		<h1>Info</h1>
      		<!-- <p @click="$router.push({path:'/faq'})">FAQ</p> -->
      		<a href="https://www.kortho.org/file/kto.pdf" target="_blank">White Paper</a>
      		<p>More</p>
      	</div>
      </div>
    </div>

		<!-- Create Contract -->
		<loginToken v-if="showToken==1" @tokenCB="tokenCB"></loginToken>
		<registToken v-if="showToken==2" @tokenCB="tokenCB"></registToken>
		<creatToken v-if="showToken==3" @tokenCB="tokenCB"></creatToken>


		<el-backtop :bottom="100">
		    <div style="{height: 100%;width: 100%;background-color: #1a89fa;border: 1px solid #1a89fa;border-radius: 40px;
		        text-align: center;line-height: 40px;font-size: 16px;color: #FFFFFF;}">UP</div>
		  </el-backtop>
	</div>
</template>

<script>
	import loginToken from '@/components/loginToken.vue'
	import registToken from '@/components/registToken.vue'
	import creatToken from '@/components/creatToken.vue'
	import { mapMutations, mapState } from 'vuex'
	export default {
		components:{loginToken,registToken,creatToken},
		props:{
			marginTop:{
				type:String,
				default: '50px'
			}
		},
		data(){
			return {
				footerList:[
					{name:'首页'},
					{name:'查看交易'},
					{name:'查看区块'},
					{name:'钱包'},
					{name:'DAPP'},
					{name:'创建合约'},
				],
				showToken:0
			}
		},
		computed: {
			...mapState(['navIndex','isPC'])
		},
		methods:{
			...mapMutations(['SET_NAVINDEX']),
			goPath(index,url){
				this.SET_NAVINDEX(index)
				this.$router.push({path:url})
			},
			tokenCB(option){
				// console.log(option)
				this.showToken = option.type
			}
		}
	}
</script>

<style lang="scss" scoped>
	.home-footer{
    position: relative;
    background: #121c34;
    .footer-content{
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      top: 0;
      left: 0;
    }
    .f-bj{
      width: 100%;
      height: 196px;
      display: block;
      margin: 0;
    }
		.footer-box{
			display: flex;
			justify-content: space-between;
			font-family: 'syRegularFont';
      padding-top: 30px;
			.footer-item{
				width: 18%;
				a{
					color: #b3bbd5;
					opacity: 0.5;
					text-decoration: none;
					padding-bottom: 10px;
					display: block;
				}
				h1{
					border-bottom: 1px solid rgba(255,255,255,.3);
					padding-bottom: 12px;
					color: #FFFFFF;
					font-size: 14px;
					margin-bottom: 12px;
				}
				p{
					color: #b3bbd5;
					opacity: 0.5;
					font-size: 14px;
					line-height: 1;
					padding-bottom: 14px;
					cursor: pointer;
				}
			}
			.contact-ico{
				a{
					display: flex;
					align-items: center;
					color: #b3bbd5;
					opacity: 0.5;
					text-decoration: none;
					img{
						width: 14px;
						height: 14px;
						margin-right: 5px;
					}
				}
			}
			.left-logo{
				width: 28%;
				
				.ff-logo{
					display: block;
					margin-bottom: 20px;
					display: flex;
					align-items: center;
					img{
						width: 30px;
					}
					span{
						font-size: 24px;
						color: #FFFFFF;
						font-family: "syMediumFont";
						font-weight: bolder;
						margin-left: 10px;
					}
				}
			}
		}
		.footer-Copyright{
			border-top: 1px solid rgba(255,255,255,.5);
			height: 44px;
			line-height: 44px;
			color: #FFFFFF;
			opacity: 0.5;
			font-size: 14px;
			text-align: left;
			margin-top: 14px;
		}
		.footer-nav{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 33px;
			border-bottom: 2px solid rgba(236,248,240,.2);
			ul{
				flex: 1;
				display: flex;
				align-items: center;
				li{
					font-size: 14px;

					color: rgba(255,255,255,.4);
					cursor: pointer;
					&:hover{
						color: rgba(255,255,255,1);
					}
					&:not(:last-child){
						margin-right: 30px;
					}
					a{
						color: rgba(255,255,255,.4);
						text-decoration: none;
						&:hover{
							color: rgba(255,255,255,1);
						}
					}
				}
			}
			.footer-logo{
				display: flex;
				align-items: center;
				opacity: 0.4;
				cursor: pointer;
				img{
					width: 24px;
					height: 24px;
					margin-right: 10px;
				}
				span{
					color: rgba(255,255,255,.4);
					font-size: 18px;
				}
			}
		}
		.footer-email{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 30px 0 34px;
			.footer-email-item{
				flex: 1;
				display: flex;
				align-items: center;
				span{
					color: rgba(255,255,255,.4);
					font-size: 14px;
					margin-right: 20px;
					padding-right: 20px;
					line-height: 1;
					&:not(:last-child){
						border-right: 2px solid rgba(236,248,240,.2);
					}
				}
			}
			p{
				color: rgba(255,255,255,.4);
				font-size: 14px;
			}
		}
	}
</style>
