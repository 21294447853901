import { queryStr } from "@/api/home.js"
const minix = {
	data() {
		return {
			searchTxt: '', // 搜索的内容
			addrNum: ''
		}
	},
	mounted() {
		var n = window.localStorage.getItem('addrNum');
		// console.log(n)
	},
	methods: {
		copyTxt(data) {
			const input = document.createElement("input");
			input.setAttribute("class", 'copy');
			document.body.appendChild(input);
			input.setAttribute("value", data);
			input.select();
			if (document.execCommand("copy")) {
				document.execCommand("copy");
			}
			document.body.removeChild(input);
			this.$notify({
				title: 'Tips',
				message: 'Copy succeeded',
				type: 'success'
			});
		},
		searchFun() {
			let reg = /^\d+$/; // 是否纯数字
			this.searchTxt = this.searchTxt.replace(" ", "")
			if (!this.searchTxt) {
				this.$notify({
					message: 'Content empty',
					duration: 1500
				});
			} else {
				queryStr({ str: this.searchTxt }).then(res => {
					if (res.errCode == 0) {
						this.addrNum = res.data
						window.localStorage.setItem('addrNum', res.data)
						if (res.data == 0) {
							//啥也不是
							this.$message({
								message: 'Invalid content',
								type: 'warning'
							});
						} else if (res.data == 1) {
							//hash
							this.$store.commit('SET_NAVINDEX', 1)
							this.$router.push({ name: 'Td20', params: { 'searchVal': this.searchTxt } })
						} else if (res.data == 2 || res.data == 5) {
							//address
							this.$store.commit('SET_NAVINDEX', 1)
							this.$router.push({ name: 'Ts', params: { 'searchVal': this.searchTxt, page: 1 } })
						} else if (res.data == 3) {
							//contract
							this.$store.commit('SET_NAVINDEX', 1)
							this.$router.push({ name: 'Ts', params: { 'searchVal': this.searchTxt, page: 1 } })
						} else if (res.data == 4) {
							if (parseInt(this.searchTxt) < 60350423) {
								alert('search error')
								return
							}
							//block
							this.$store.commit('SET_NAVINDEX', 2)
							this.$router.push({ path: '/Bd', query: { 'searchVal': this.searchTxt } })
						} else if (res.data == 6) {
							//hash   1.0 数据  ---------------------------
							this.$store.commit('SET_NAVINDEX', 1)
							this.$router.push({ name: 'Td', params: { 'searchVal': this.searchTxt } })
						}
					}
				})
			}
		}
	}
}


export default minix
