<template>
	<div class="c-token">
		<el-dialog title="Create Contract" custom-class="create-contract" :visible.sync="loginTokenShow" 
			width="20%" :close-on-click-modal="false" @closed="closed">
			<div class="c-token-item">
				<span>Email</span>
				<input type="text" placeholder="Enter your email" v-model="email"/>
			</div>
			<div class="c-token-item">
				<span>Password</span>
				<input type="password" placeholder="Enter the password"  v-model="pwd"/>
			</div>
			<div class="c-token-box">
				<span @click="regist()">Register</span>
				<span @click="login()">Login</span>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { loginContractApi } from '@/api/home.js'
	export default {
		data(){
			return {
				loginTokenShow:true,
				email:'',
				pwd:''
			}
		},
		methods:{
			regist(){
				this.$emit('tokenCB',{type:2,d:''})
			},
			login(){
				if(this.email==''){
					this.$notify({
						title: 'warning',
						message: 'The mailbox cannot be empty',
						type: 'warning'
					});
					return
				}
				if(this.pwd==''){
					this.$notify({
						title: 'warning',
						message: 'The password cannot be empty',
						type: 'warning'
					});
					return
				}
				loginContractApi({password:this.pwd,email:this.email}).then(res=>{
					if(res.code==0){
						this.$emit('tokenCB',{type:3,d:''})
					}
				})
				
			},
			closed(){
				this.$emit('tokenCB',{type:0,d:''})
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.c-token-item{
		display: flex;
		flex-direction: column;
		// align-items: center;
		margin-bottom: 20px;
		span{
			width: 80px;
			font-size: 14px;
			margin-bottom: 8px;
			// text-align: right;
		}
		input{
			flex: 1;
			line-height: 100%;
			height: 100%;
			border: 1px solid #AFBACC;
			font-size: 14px;
			padding: 8px 12px;
			color: #545866;
			border-radius: 3px;
			&::-webkit-input-placeholder{
				color: #AFBACC;
			}
			&::-ms-input-placeholder{
				color: #AFBACC;
			}
			&::-moz-placeholder{
				color: #AFBACC;
			}
			&::-moz-placeholder{
				color: #AFBACC;
			}
		}
	}
	.c-token-box{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-top: 20px;
		span:nth-child(1){
			padding: 5px 0px;
			text-align: center;
			border-radius: 4px;
			border: 1px solid #1a89fa;
			cursor: pointer;
			color: #1a89fa;
			width: 80px;
		}
		span:nth-child(2){
			padding: 5px 0px;
			text-align: center;
			border-radius: 4px;
			border: 1px solid #1a89fa;
			background: #1a89fa;
			color: #FFFFFF;
			cursor: pointer;
			margin-left: 10px;
			width: 80px;
		}
	}
</style>
