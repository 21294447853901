<template>
	<div class="">
		<div>
			<el-table :data="orderlist" 
				:show-header="false"
				style="width: 100%" 
				:header-cell-style="headerStyle" 
				:row-style="rowStyle">

				<el-table-column label="Transaction" width="222">
					<template slot-scope="scope">
						<div class="trad-box">
							<div class="trad-tx" :class="{ 'gary-gujia': scope.row.gj }">BK</div>
							<div class="trad-box-right" v-if="!scope.row.gj">
								<div class="trad-hash">
									<span :class="{ 'gary-gujia': scope.row.gj, 'click-item': true }" @click="
                scope.row.Block && goBlockDetail(scope.row.Block)">{{ scope.row.Block }}</span>
								</div>
								<span :class="{ 'gary-gujia': scope.row.gj }">{{ scope.row.CreateTime | timeStr }}</span>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="From"  width="200">
					<template slot-scope="scope">
						<div class="trad-addr-box">
							<div class="trad-addr-box-item">
								<span style="margin-right:8px;">Miner </span>
								<el-tooltip placement="bottom">
									<div slot="content" style="width: 150px;">{{ scope.row.MIne }}</div>
									<span :class="{ 'gary-gujia': scope.row.gj }">
										{{ scope.row.MIne | addrStr1(6) }}</span>
								</el-tooltip>
							</div>
							<div class="trad-addr-box-item">
								<span class="click-item" style="margin-right:8px;">{{scope.row.Count}}</span>
								<div class="box-addr">
									<span>txns</span>
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="Value" align="right">
					<template slot-scope="scope">
						<span class="v-bj" :class="{ 'gary-gujia': scope.row.gj }">{{ scope.row.TotalAmount }} KTO</span>
					</template>
				</el-table-column>
			</el-table>
			
		</div>
	</div>
</template>

<script>
import { getLateTxData,GetBlockbyNum } from "@/api/home";
import { dateFormat, timeFormat } from "@/utils/lib.js";
import testData from "@/utils/testData";
export default {
	data() {
		return {
			newBlock: testData.newBlock, //最新区块
			orderlist: testData.homeorderlist,
			num: 0,
			show: false,
			zancun: [],
			token20: false,
			menu: 0,
			headerStyle: {
				color: "#7E8499",
				"font-weight": "100",
				background: "#F8FAFD",
				padding: "6px 10px",
				"border-top": "1px solid #E7EAF3",
				"line-height": 1,
			},
			rowStyle: {
				padding: "0px 10px",
				"font-size": "13px",
				"font-family": "numRegularFont",
				color: "#7E8499",
				"line-height": 1,
			},
		};
	},
	created() {
		this.getBlocak();
	},
	methods: {
		//根据地质查交易
		getLateTxData() {
			getLateTxData({}, false).then((res) => {
				if (res.errCode == 0) {
					this.show = true;
					this.zancun = res.data;
					this.orderlist = res.data
				}
			});
		},
		showMore() {
			this.orderlist = this.zancun;
			this.show = false;
		},
		getBlocak(){
			GetBlockbyNum({page:1,size:10},false).then(data=>{
				this.orderlist = data.data.slice(0,8)
				// this.blockListData=this.blockList.slice(0,10)
			})
		},
		// 区块详情
		goBlockDetail(item) {
			this.$store.commit("SET_NAVINDEX", 1);
			this.$router.push({
				path: "/Bd",
				query: {
					searchVal: item,
				},
			});
		},
		//交易详情
		goTransferDetail(item) {
			this.$router.push({
				name: "Td20",
				params: {
					searchVal: item,
				},
			});
		},
		//地址查询交易
		goTransfer(row, direction) {
			this.$store.commit("SET_NAVINDEX", 1);
			this.$router.push({
				name: "Ts",
				params: {
					searchVal: direction == "to" ? row.ToAddr : row.FromAddr,
					direction: direction,
					page: 1
				},
			});
		},
		fromHas(k, n) {
			if (k) {
				var a = k.split("");
				if (a[0] != "K") {
					return n == "from" ? "Coinbase" : "";
				} else {
					return k;
				}
			}
		},

	},
	filters: {
		timeStr(n) {
			if (n) {
				return n.split(" ")[1]
			} else {
				return n
			}
		},
		addrStr1(str,n) {
			n=n?n:5
			if (str) {
				return str.substring(0, n) + '...' + str.substring(str.length - n, str.length)
			}
		},
		hashStr(str,n) {
			n=n?n:12
			if (str) {
				return str.substring(0, n) + '...' + str.substring(str.length - n, str.length)
			}
		},
		addrStr(str, n) {
			if (str) {
				return str.substring(0, n ? n : 14) + "...";
			} else {
				return str;
			}
		},
		tokenStr(script) {
			if (script) {
				var daibiarry = script.split(" ");
				if (daibiarry[2] * 1 >= 0) {
					daibiarry[2] = parseFloat(daibiarry[2]);
				} else {
					daibiarry[2] = parseFloat(daibiarry[3]);
				}
				var symbol = daibiarry[1].replace(/"/g, "");
				var num = daibiarry[2];
				let temp;
				if (symbol == "GPS") {
					temp = num / Math.pow(10, 11) + " " + symbol;
				} else {
					temp = num / Math.pow(10, 8) + " " + symbol;
				}
				return temp;
			} else {
				return script;
			}
		},
	},
};
</script>

<style scoped="scoped" lang="scss">
.trad-box {
	display: flex;
	align-items: center;
	font-family: 'PingFangSC-Semibold';
}
.v-bj{
	background: #EAEDFF;
	white-space: nowrap;
	padding: 2px 6px;
	border-radius: 2px;
	font-family: 'numMediumFont';
}
.trad-tx{
	width: 40px;
	height: 40px;
	background: #EAEDFF;
	border-radius: 50%;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #2B2D3B;
}
.trad-addr-box-item{
	display: flex;
	align-items: center;
	font-family: 'PingFangSC-Semibold';
}
.trad-box-right {
	padding-left: 10px;
}

.trad-addr {
	display: flex;
	align-items: center;

	img {
		margin: 0 5px;
	}
}

.home-main {
	border: 1px solid #e7eaf3;
	margin-top: 20px;
	border-radius: 10px;
	box-sizing: border-box;
	box-shadow: 0px 6px 12px rgba(158, 158, 161, 0.12);
	background: #ffffff;

	.home-block-title {
		display: flex;
		padding-left: 20px;
		height: 50px;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		// flex-direction: column;
		p {
			color: #2a2c33;
			font-size: 16px;
			font-family: "syMediumFont";
			margin-right: 30px;
		}
	}

	.show-more {
		display: flex;
		justify-content: center !important;
		cursor: pointer;

		img {
			display: block;
			margin-top: 4px;
			margin-left: 2px;
		}

		span {
			font-size: 14px;
			color: #121d37;
		}
	}

	.table-body {
		padding: 0 20px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #7e8499;
		box-sizing: border-box;
		font-family: "numRegularFont";
	}
}

.p-active {
	font-weight: bolder;
}
</style>
