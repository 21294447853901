var testData = {
	newBlock:'--',//最新区块
	newPrice:'--',//最新价格
	homeList:[
		{
			timestamp:'0',
			height:'--------',
			reward:'0',
			gj:true,
			miner:'-----------------------------',
			hash:'----------------------------------------',
		},
		{
			timestamp:'0',
			height:'--------',
			reward:'0',
			gj:true,
			miner:'-----------------------------',
			hash:'----------------------------------------',
		},
		{
			timestamp:'0',
			height:'--------',
			reward:'0',
			gj:true,
			miner:'-----------------------------',
			hash:'----------------------------------------',
		},
		{
			timestamp:'0',
			height:'--------',
			reward:'0',
			gj:true,
			miner:'-----------------------------',
			hash:'----------------------------------------',
		},
		{
			timestamp:'0',
			height:'--------',
			reward:'0',
			gj:true,
			miner:'-----------------------------',
			hash:'----------------------------------------',
		}
	],
	orderlist:[
		{
			FromAddr:'----------------------------------------',
			ToAddr:'----------------------------------------',
			time:'0',
			BlockNum:'--------',
			Value:'0',
			CreateTime:'--------',
			gj:true,
			Hash:'----------------------------------------',
		},
		{
			FromAddr:'----------------------------------------',
			ToAddr:'----------------------------------------',
			time:'0',
			BlockNum:'--------',
			Value:'0',
			CreateTime:'--------',
			gj:true,
			Hash:'----------------------------------------',
		},
		{
			FromAddr:'----------------------------------------',
			ToAddr:'----------------------------------------',
			time:'0',
			BlockNum:'--------',
			Value:'0',
			CreateTime:'--------',
			gj:true,
			Hash:'----------------------------------------',
		},
		{
			FromAddr:'----------------------------------------',
			ToAddr:'----------------------------------------',
			time:'0',
			BlockNum:'--------',
			Value:'0',
			CreateTime:'--------',
			gj:true,
			Hash:'----------------------------------------',
		},
		{
			FromAddr:'----------------------------------------',
			ToAddr:'----------------------------------------',
			time:'0',
			BlockNum:'--------',
			Value:'0',
			CreateTime:'--------',
			gj:true,
			Hash:'----------------------------------------',
		}
	],
	blocklist:[
		{
			miner:'----------------------------------------',
			to:'----------------------------------------',
			timestamp:'0',
			height:'--------',
			reward:'0',
			fee:'--------',
			gj:true,
			Tnx:0,
			hash:'----------------------------------------',
		},
		{
			miner:'----------------------------------------',
			to:'----------------------------------------',
			timestamp:'0',
			height:'--------',
			reward:'0',
			fee:'--------',
			gj:true,
			Tnx:0,
			hash:'----------------------------------------',
		},
		{
			miner:'----------------------------------------',
			to:'----------------------------------------',
			timestamp:'0',
			height:'--------',
			reward:'0',
			fee:'--------',
			gj:true,
			Tnx:0,
			hash:'----------------------------------------',
		},
		{
			miner:'----------------------------------------',
			to:'----------------------------------------',
			timestamp:'0',
			height:'--------',
			reward:'0',
			fee:'--------',
			gj:true,
			Tnx:0,
			hash:'----------------------------------------',
		},
		{
			miner:'----------------------------------------',
			to:'----------------------------------------',
			timestamp:'0',
			height:'--------',
			reward:'0',
			fee:'--------',
			gj:true,
			Tnx:0,
			hash:'----------------------------------------',
		}
	],
	homeorderlist:[
		{
			from:'----------------------------------------',
			to:'----------------------------------------',
			time:'0',
			blocknumber:'--------',
			amount:'0',
			fee:'--------',
			gj:true,
			hash:'----------------------------------------',
		},
		{
			from:'----------------------------------------',
			to:'----------------------------------------',
			time:'0',
			blocknumber:'--------',
			amount:'0',
			fee:'--------',
			gj:true,
			hash:'----------------------------------------',
		},
		{
			from:'----------------------------------------',
			to:'----------------------------------------',
			time:'0',
			blocknumber:'--------',
			amount:'0',
			fee:'--------',
			gj:true,
			hash:'----------------------------------------',
		},
		{
			from:'----------------------------------------',
			to:'----------------------------------------',
			time:'0',
			blocknumber:'--------',
			amount:'0',
			fee:'--------',
			gj:true,
			hash:'----------------------------------------',
		},
		{
			from:'----------------------------------------',
			to:'----------------------------------------',
			time:'0',
			blocknumber:'--------',
			amount:'0',
			fee:'--------',
			gj:true,
			hash:'----------------------------------------',
		}
	],
	minerlist:[
		{
			Addr:'----------------------------------------',
			Total:'0',
			Name:'--------',
			gj:true,
		},
		{
			Addr:'----------------------------------------',
			Total:'0',
			Name:'--------',
			gj:true,
		},
		{
			Addr:'----------------------------------------',
			Total:'0',
			Name:'--------',
			gj:true,
		},
		{
			Addr:'----------------------------------------',
			Total:'0',
			Name:'--------',
			gj:true,
		},
		{
			Addr:'----------------------------------------',
			Total:'0',
			Name:'--------',
			gj:true,
		},{
			Addr:'----------------------------------------',
			Total:'0',
			Name:'--------',
			gj:true,
		},
		{
			Addr:'----------------------------------------',
			Total:'0',
			Name:'--------',
			gj:true,
		},
		{
			Addr:'----------------------------------------',
			Total:'0',
			Name:'--------',
			gj:true,
		}
	],
}
export default testData
