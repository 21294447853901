import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueWorker from 'vue-worker'
Vue.use(VueWorker)

// import './utils/solc.js'

import 'nprogress/nprogress.css' // Progress 进度条样式

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// import VueI18n from 'vue-i18n';
// Vue.use(VueI18n)

// var lang = window.sessionStorage.getItem('lang')
// store.commit('SET_LANG',lang?lang:'en')
// const i18n = new VueI18n({
//   locale: 'en',
//   messages: {
//     'zh': require('./assets/i18n/zh.js'),  //
//     'en': require('./assets/i18n/en.js')
//   }
// })

import './assets/css/common.css';
import './assets/css/media1500.css';
import './assets/css/media1000.css';
import './assets/css/media750.css';

import headerBar from './views/header.vue'
Vue.component("headerBar",headerBar)

import footerBar from './views/footer.vue'
Vue.component("footerBar",footerBar)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  // i18n,
  render: h => h(App)
}).$mount('#app')
