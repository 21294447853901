<template>
	<div class="home">
		<!-- 头部导航 -->
		<headerBar :hasSearch="false"></headerBar>
		<div class="home-flex">
			<div class="banner" :style="{ backgroundImage: 'url(' + require('../assets/img/bbb.png') + ')' }">
				<div class="main-Width banner-main">
					<p class="b-t1">KorthoChain Smart Chain Explorer</p>
					<div class="ban-ss">
						<img src="../assets/img/ss.png" />
						<input v-model="searchTxt" type="text" placeholder="Search by Address / Txn Hash / Block"
							@keyup.enter="searchFun()" />
					</div>
					<!-- <p class="b-t2">Sponsored: Fairspin - Spin the Slot & Get bonuses. Play & Earn TFS token with
						Fairspin.io.</p> -->
				</div>
			</div>



			<div class="container">
				<!-- 图表统计 -->
				<div class="main-Width sys-total">
					<div class="sys-left">
						<div class="echart-left-top">
							<div class="sys-item">
								<img src="../assets/img/qukuai.png" />
								<div class="echart-left-top-item">
									<p><span>Blocks</span><span>Unit</span></p>
									<p><span class="tsfont">{{ totalData.Height }}</span><span></span></p>
								</div>
							</div>
							<div class="sys-item">
								<img src="../assets/img/zhiya.png" />
								<div class="echart-left-top-item">
									<p><span>Pledge amount</span><span>Unit</span></p>
									<p><span class="tsfont">{{ totalData.PledgeAmount }}</span><span>KTO</span></p>
								</div>
							</div>
							<div class="sys-item">
								<img src="../assets/img/chukuai.png" />
								<div class="echart-left-top-item">
									<p><span>Block interval</span><span>Unit</span></p>
									<p><span class="tsfont">{{ totalData.AveTime }}</span><span>S</span></p>
								</div>
							</div>
						</div>
						<div class="echart-left-bottom">
							<div class="sys-item">
								<img src="../assets/img/shouyi.png" />
								<div class="echart-left-top-item">
									<p><span>Block reward</span><span>Unit</span></p>
									<p><span class="tsfont">{{ totalData.Reward }}</span><span>KTO</span></p>
								</div>
							</div>
							<div class="sys-item">
								<img src="../assets/img/24.png" />
								<div class="echart-left-top-item">
									<p><span>24-hour output</span><span>Unit</span></p>
									<p><span class="tsfont">{{ totalData.RecentOutput }}</span><span>KTO</span></p>
								</div>
							</div>
							<div class="sys-item">
								<img src="../assets/img/zongliang.png" />
								<div class="echart-left-top-item">
									<p><span>Circulation</span><span>Unit</span></p>
									<p><span class="tsfont">{{ totalData.Circulation }}</span><span>KTO</span></p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="main-Width home-echart-box">
					<div class="home-echart-left">
						<div class="r-title">Latest Blocks</div>
						<!-- <myEchart></myEchart> -->
						<BlockList class="trad-list"></BlockList>
						<div class="h-view-all" @click="toBlock()">View all blocks</div>
					</div>
					<div class="home-echart-right">
						<div class="r-title">Latest Transactions</div>
						<!-- 最新交易 -->
						<tradeTable class="trad-list"></tradeTable>
						<div class="h-view-all" @click="toMoreTs()">View all transactions</div>
					</div>
				</div>
			</div>

			<!-- 最新区块 -->
			<!-- 持币地址 -->
			<holdCoin></holdCoin>
			<footerBar></footerBar>
		</div>
	</div>
</template>

<script>
import search from '@/utils/search.js'
import { GetBlock_FY, GetMaxBlockNum, GetBlockbyNum, GetUSDkto, GetTotalData } from '@/api/home'
import { mapMutations, mapState } from 'vuex'
import testData from '@/utils/testData'
import { dateFormat } from '@/utils/lib.js'
import blockTable from './homeChilds/blockTable.vue'
import tradeTable from './homeChilds/tradeTable.vue'
import BlockList from './homeChilds/BlockList.vue'
import myEchart from './homeChilds/echart.vue'
import holdCoin from './holdcoin.vue'
export default {
	name: "home",
	components: { tradeTable, myEchart, holdCoin,BlockList },
	mixins: [search],
	data() {
		return {
			totalData: {},
			newBlock: testData.newBlock,//最新区块
			newPrice: testData.newPrice,//最新价格
			blockList: testData.homeList,
			timer: null,
			activeaddr: '0.00',
			totalamount: '0.00',
			totaltxs: '0.00',
			amounts: '0.00'
		}
	},
	computed: {
		...mapState(['lang'])
	},
	created() {
		this.getTotalData()
	},
	methods: {
		// 区块详情
		goBlockDetail(item) {
			this.$store.commit('SET_NAVINDEX', 2)
			this.$router.push({ path: '/Bd', query: { searchVal: item } })
		},
		//交易列表
		goTransfer(item) {
			this.$store.commit('SET_NAVINDEX', 1)
			// this.$router.push({path:'/Ts',query:{'searchVal':item}})
			this.$router.push({ name: 'Ts', params: { 'searchVal': item } })
		},
		getTotalData() {
			GetTotalData({}, false).then(res => {
				if (res.errCode == 0) {
					this.totalData = res.data
				}
			})
		},
		toMoreTs(){
			this.$router.push({path:'/MoreTs'})
		},
		toBlock(){
			this.$router.push({path:'/MoreBlock'})
		},
	},
	filters: {
		numformat(num) {
			return (num + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
		},
		liquidFormat(num) {
			var n = (((num - 31536000) * 0.39568 + 31536000 * 0.4946).toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
			return n
		},
		timeStr(n) {
			return dateFormat(n)
		},
		addrStr(str, n) {
			if (str) {
				return str.substring(0, n ? n : 14) + '...'
			} else {
				return str
			}

		}
	}
}
</script>
<style lang="scss" scoped>
.banner-main {
	display: flex;
	flex-direction: column;
	height: 254px;
	justify-content: center;

	.b-t1 {
		color: #FFFFFF;
		font-size: 24px;
	}

	.b-t2 {
		color: #FFFFFF;
		font-size: 12px;
	}

	.ban-ss {
		display: flex;
		align-items: center;
		height: 44px;
		background: #FFFFFF;
		border-radius: 5px;
		width: 80%;
		margin: 15px 0;
		box-sizing: border-box;
		padding: 0 15px;

		img {
			margin-right: 15px;
		}

		input {
			flex: 1;
			border: none;
			outline: none;
			font-size: 16px;
		}
	}
}
.h-view-all{
	height: 44px;
	background: #EAEDFF;
	border-radius: 5px;
	margin:10px 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #0965FF;
	font-weight: bold;
	font-size: 16px;
	cursor: pointer;
	user-select: none;
	&:active{
		opacity: 0.7;
	}
}
.r-title {
	font-size: 14px;
	font-weight: bolder;
	padding: 20px 30px;
}

.trad-list {
	flex: 1;
	overflow-y: auto;
}

.home-top {
	// padding: 0px 0 50px;
	background: #3D6899;
	box-sizing: border-box;
	height: 370px;
}

.sys-total {
	height: 200px;
	border: 1px solid #E7EAF3;
	// box-shadow: 0px 6px 12px rgb(158 158 161 / 12%);
	box-shadow: 0px 7px 14px 0px rgba(45,88,245,0.2000);
	background: #FFFFFF;
	opacity: 1;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 24px;
}

.sys-item {
	display: flex;
	align-items: center;
	width: 33%;
	justify-content: space-between;

	img {
		width: 46px;
		height: 46px;
		margin-right: 8px;
		margin-left: 20px;
	}
}

.home-search {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-top: 160px;

	.search-logo {
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			font-size: 40px;
			color: #FFFFFF;
			letter-spacing: 10px;
			line-height: 1;
		}
	}

	.search-main {
		width: 736px;
		height: 50px;
		padding: 6px 6px 6px 15px;
		border-radius: 6px;
		margin: 30px auto 0px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #FFFFFF;
		box-sizing: border-box;

		img {
			width: 16px;
			height: 16px;
			display: block;
		}

		.search-btn {
			background: rgba(26, 137, 250, .2);
			width: 80px;
			height: 38px;
			line-height: 38px;
			text-align: center;
			border-radius: 4px;
			color: #1A89FA;
			cursor: pointer;
		}

		input {
			flex: 1;
			line-height: 100%;
			height: 100%;
			border: none;
			font-size: 14px;
			padding: 0 20px 0 8px;
			color: #545866;

			&::-webkit-input-placeholder {
				color: #AFBACC;
			}

			&::-ms-input-placeholder {
				color: #AFBACC;
			}

			&::-moz-placeholder {
				color: #AFBACC;
			}

			&::-moz-placeholder {
				color: #AFBACC;
			}
		}
	}
}

.home-total {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #FFFFFF;
	box-shadow: 0px 6px 12px rgba(158, 158, 161, 0.12);
	border-radius: 10px;
	padding: 30px 0;
	margin-top: -30px;

	.home-total-item {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 25%;
		height: 60px;

		&:not(:last-child) {
			border-right: 1px solid #E7EAF3;
		}

		img {
			width: 32px;
			height: 32px;
			margin-right: 10px;
		}

		.home-total-num {
			display: flex;
			flex-direction: column;

			span:nth-child(2) {
				font-size: 20px;
				font-family: 'numMediumFont';
				color: #2A2C33;
				line-height: 1;
			}

			span:nth-child(1) {
				font-size: 14px;
				color: #7E8499;
				line-height: 1;
				margin-bottom: 8px;
			}
		}
	}
}

.home {
	height: 100%;
	display: flex;
	flex-direction: column;
	.home-flex{
		flex: 1;
		overflow-y: auto;
	}
}

.home-main {
	border: 1px solid #E7EAF3;
	margin-top: 20px;
	border-radius: 10px;
	box-sizing: border-box;
	box-shadow: 0px 6px 12px rgba(158, 158, 161, 0.12);
	background: #FFFFFF;

	.home-block-title {
		padding-left: 20px;
		height: 50px;
		display: flex;
		justify-content: center;
		flex-direction: column;

		p {
			color: #2A2C33;
			font-size: 16px;
			font-family: 'syMediumFont';
		}
	}

	.home-Links {
		padding: 0 20px 20px;

		a {
			text-decoration: none;
			font-size: 14px;
			color: #0022BB;
			font-family: 'syMediumFont';

			&:not(:last-child) {
				margin-right: 20px;
			}
		}
	}

	.table-header {
		padding: 0 20px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #F8FAFD;
		border-top: 1px solid #E7EAF3;
		color: #7E8499;
		box-sizing: border-box;
		font-size: 14px;
	}

	.center {
		text-align: center !important;
	}

	.table-body {
		padding: 0 20px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #E7EAF3;
		color: #7E8499;
		box-sizing: border-box;
		font-family: 'numRegularFont';
	}
}

.home-echart-box {
	display: flex;
	justify-content: space-between;
	padding-top: 24px;
	flex-wrap: wrap;

	.home-echart-left {
		width: 590px;
		// height: 412px;
		border: 1px solid #E7EAF3;
		box-shadow: 0px 6px 12px rgba(158, 158, 161, 0.12);
		background: #FFFFFF;
		opacity: 1;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}

	.home-echart-right {
		width: 590px;
		// height: 412px;
		border: 1px solid #E7EAF3;
		box-shadow: 0px 6px 12px rgba(158, 158, 161, 0.12);
		opacity: 1;
		border-radius: 10px;
		background: #FFFFFF;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
}

.echart-left-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 10px;

	.sys-item {
		&:not(:last-child) {
			border-right: 1px solid #f1f1f1;
		}
	}
}

.echart-left-bottom {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 10px;
	border-top: 1px solid #f1f1f1;

	.sys-item {
		&:not(:last-child) {
			border-right: 1px solid #f1f1f1;
		}
	}
}

.echart-left-top-item {
	flex: 1;
	display: flex;
	flex-direction: column;
	padding-right: 30px;

	p {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #1E1E1E;
		// line-height: 1;
		font-family: 'PingFang SC';
		font-size: 14px;
		padding: 2px 0;

		.tsfont {
			color: #0965FF;
			font-size: 18px;
			line-height: 1;
			font-family: 'numMediumFont';
		}
	}

	&:not(:last-child) {
		border-right: 1px solid #E7EAF3;
		;
	}

}
</style>
