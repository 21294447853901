<template>
	<div class="header-box">
		<div class="header-content">
			<div class="main-Width home-header">
				<div class="h-logo" @click="goPath(0, '/')">
					<img src="../assets/img/logo.png" class="n-logo" />
					<img src="../assets/img/logo.png" class="sn-logo" />
					<span> KorthoChain </span>
				</div>
				<div class="h-rbox">
					<div class="home-nav">
						<span :class="{ 'li-active': navIndex == 0 }" @click="goPath(0, '/')">Home</span>
						<span :class="{ 'li-active': navIndex == 2 }" @click="goPath(2, '/miner')">Miner</span>
						<span :class="{ 'li-active': navIndex == 3 }" @click="goPath(3, '/W')">Wallet</span>
						<!-- <span :class="{'li-active':navIndex==4}" @click="goPath(4,'/holdcoin')">Holdcoin</span> -->
					</div>
					<!-- 搜索 -->
					<div class="head-search" v-if="routerName!='Home'">
						<img src="../assets/img/ss.png" />
						<input v-model="searchTxt" placeholder="Search by Address / Txn Hash / Block"
							@keyup.enter="searchFun()" />
						<div class="search-btn" @click="searchFun()">Search</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import search from '@/utils/search.js'
import {
	mapMutations,
	mapState
} from 'vuex'
export default {
	props: {
		hasSearch: {
			type: Boolean,
			default: true
		}
	},
	mixins: [search],
	data() {
		return {
			langeBoxShow: false,
			showPhoneNav: false,
			routerName:''
		}
	},
	computed: {
		...mapState(['lang', 'navIndex', 'isPC'])
	},
	watch:{
		$route(to,from){
			this.routerName = to.name
		}
	},
	mounted(){
		console.log(this.$route.name)
		this.routerName = this.$route.name
	},
	methods: {
		...mapMutations(['SET_LANG', 'SET_NAVINDEX']),
		changeLang(lang) {
			this.$i18n.locale = lang
			this.SET_LANG(lang)
		},
		goPath(index, url) {
			this.navIdx = index
			this.SET_NAVINDEX(index)
			if (index == 1) {
				this.$router.push({
					name: 'Block',
					params: {
						'searchVal': '-',
						page: 1
					}
				})
				return
			}
			if (index == 2) {
				this.$router.push({
					name: 'miner'
				})
				return
			}
			if (index == 3) {
				window.location.href = 'https://www.kortho.org/miner'
				return
			}
			if (index == 4) {
				this.$router.push({
					name: 'holdcoin'
				})
				return
			}
			this.$router.push({
				path: url
			})
		},
		goDapp() {
			window.location.href = 'https://www.qutaojing.cn/download.html'
		},
	}
}
</script>

<style lang="scss" scoped>
.phone-menu {
	display: none;
}
.sn-logo{
	display: none;
}
.h-logo {
	display: flex;
	align-items: flex-start;
	margin-top: 15px;
	cursor: pointer;
	span {
		color: #1E1E1E;
		font-size: 24px;
		font-family: 'syMediumFont';
		margin-left: 13px;
	}
}

.header-box {
	// height: 176px;
	// position: fixed;
	// top: 0;
	// left: 0;
	width: 100%;
	background: #FFFFFF;

	// z-index: 100;
	.header-content {
		// position: absolute;
		// top: 0;
		// left: 0;
		// z-index: 100;
		width: 100%;
		height: 100%;
		// padding-bottom: 40px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-bottom: 1px solid #f1f1f1;
	}

	.home-header {
		display: flex;
		// align-items: center;
		justify-content: space-between;
		// height: 64px;
	}

	.home-nav {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 60px;
		span {
			cursor: pointer;
			margin-left: 26px;
			font-size: 16px;
			color: #1E1E1E;
		}

		.li-active {
			color: #0965FF;
			font-weight: bolder;
			position: relative;

			&::after {
				content: "";
				position: absolute;
				bottom: -5px;
				left: 50%;
				margin-left: -10px;
				width: 20px;
				height: 2px;
				// background: #FFFFFF;
			}
		}
	}

	.head-search {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 40px;
		background: #FFFFFF;
		border: 1px solid #BCC6FF;
		border-radius: 2px;
		overflow: hidden;
		padding: 6px 0px 6px 12px;
		box-sizing: border-box;
		width: 500px;
		margin-bottom: 20px;
		overflow: hidden;

		img {
			width: 18px;
			height: 18px;
			display: block;
		}

		.search-btn {
			background: #eaedff;
			width: 80px;
			height: 38px;
			line-height: 38px;
			text-align: center;
			color: #0965ff;
			cursor: pointer;
		}

		input {
			flex: 1;
			line-height: 100%;
			height: 100%;
			border: none;
			font-size: 14px;
			padding: 0 20px 0 8px;
			color: #545866;
			font-family: 'PingFangSC-Regular';
			&::-webkit-input-placeholder {
				color: #C0C0C0;
			}

			&::-ms-input-placeholder {
				color: #C0C0C0;
			}

			&::-moz-placeholder {
				color: #C0C0C0;
			}

			&::-moz-placeholder {
				color: #C0C0C0;
			}
		}
	}
}
</style>
