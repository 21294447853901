import axios from 'axios'
import store from '@/store/index.js'
const qs = require('qs')
// const basePath = process.env.NODE_ENV === 'production' ? '' : '/api'
import { Notification, Loading } from 'element-ui';
import router from '@/router/index.js'
import NProgress from 'nprogress'
// 创建axios实例
let switchs = true
const ajax = axios.create({
	//baseURL: basePath, //config.url,
	timeout: 50000, // 请求超时时间
})
// ajax.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded";
ajax.interceptors.request.use(
	config => {
		return config;
	},
	error => {
		console.log(err)
		return Promise.reject(err);
	}
);
// respone拦截器
ajax.interceptors.response.use(
	// token 可以判断已过期，重定向到登录页面
	response => {
		switch (response.data.isSuccess||response.data.code||response.data.errCode) {
			case 0:
				return response.data;
				break
			case true:
				return response.data;
				break
			case 1001:
				Notification({
					title: 'Warning',
					message: response.data.data,
					type: 'warning'
				})
				return response.data;
				break
			default:
				return response.data
				break
		}
	},
	error => {
		Notification({
			message: error
		})
		NProgress.done();
		return {
			msg: error,
			code: 9999
		}
		return Promise.reject(error)
	}
)
/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url, params,loading=true) {
	return new Promise((resolve, reject) => {
		if(loading){
			NProgress.start()
		}
		ajax({
				url: url,
				method: 'GET',
				params: params
			}).then(res => {
				if(loading){
					NProgress.done();
				}
				if (res.errorcode == 1001) {
					// this.$toast('登录超时,' + res.data.message);
					// this.$router.push({ path: '/' });
					return
				}
				resolve(res);
			})
			.catch(err => {
				if(loading){
					NProgress.done();
				}
				reject(err)
			})
	})
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data,loading=true) {
	return new Promise((resolve, reject) => {
		if(loading){
			NProgress.start()
		}
		ajax({
			url: url,
			method: 'POST',
			data: data,
		}).then(res => {
			if(loading){
				NProgress.done();
			}
			resolve(res);
		}).catch(err => {
			if(loading){
				NProgress.done();
			}
			reject(err)
		})
	})
}
// export function post(url, data = {}) {
//   return new Promise((resolve, reject) => {
//     axios.post(config.url + url, qs.stringify(data)).then(response => {
//       console.log(response)
//       resolve(response)
//     }, err => {
//       reject(err)
//     }).catch(err => {
//       console.log(err)
//     })
//   })
// }
export default ajax
