import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
var navIndex = window.sessionStorage.getItem('navIndex')
var lang = window.sessionStorage.getItem('lang')

export default new Vuex.Store({
	state: {
		lang: lang?lang:'zh',
		isPC: true,
		navIndex: navIndex ? navIndex : 0, //首页
	},
	mutations: {
		SET_LANG(state, lang) {
			state.lang = lang
			window.sessionStorage.setItem('lang', lang)
		},
		SET_NAVINDEX(state, navIndex) {
			state.navIndex = navIndex
			window.sessionStorage.setItem('navIndex', navIndex)
		},
		SET_ISPC(state, isPC) {
			state.isPC = isPC
		},
	},
	actions: {},
	modules: {}
})
