<template>
	<div class="miner">

		<div class="main-Width home-main">
			<div class="banner-img">
				<img src="../assets/img/coinhold.png" >
			</div>
			<!-- <div class="home-block-title">
				<p class="p-active">Miners</p>
			</div> -->
			<div>
				<el-table :data="orderlist" style="width: 100%" :header-cell-style="headerStyle" :row-style="rowStyle">
					<el-table-column
						fixed
						label="Ranking"
					    type="index"
					    width="100">
					</el-table-column>
					<el-table-column label="Address" min-width="180">
						<template slot-scope="scope">
							<span style="white-space: nowrap" :class="{ 'gary-gujia': scope.row.gj }">{{scope.row.Addr}}</span>
						</template>
					</el-table-column>
					<el-table-column label="Coin holdings" align="center" min-width="130">
						<template slot-scope="scope">
							<span :class="{ 'gary-gujia': scope.row.gj }">{{ scope.row.NowAmount }} KTO</span>
						</template>
					</el-table-column>
					<el-table-column label="Percentage" align="center" min-width="130">
						<template slot-scope="scope">
							<span :class="{ 'gary-gujia': scope.row.gj }">{{ persand(scope.row.NowAmount)}}%</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
	import {getMaxAddrApi,GetTotalData} from "@/api/home";
	import {dateFormat,timeFormat} from "@/utils/lib.js";
	import testData from "@/utils/testData";
	export default {
		data() {
			return {
				newBlock: testData.newBlock, //最新区块
				orderlist: testData.minerlist,
				num: 0,
				show: false,
				zancun: [],
				token20: false,
				menu: 0,
				Circulation:0,
				headerStyle: {
					color: "#1E1E1E",
					"font-weight": "100",
					background: "#F8FAFD",
					padding: "6px 10px",
					"border-top": "1px solid #E7EAF3",
					'font-family': 'PingFangSC-Semibold',
					"line-height": 1,
				},
				rowStyle: {
					padding: "2px 10px",
					'height':'54px',
					"font-size": "13px",
					'font-family': 'PingFangSC-Semibold',
					color: "#1E1E1E",
					"line-height": 1,
				},
			};
		},
		created() {
			this.getTotalData();
			this.minerList();

		},
		methods: {
			getTotalData(){
				GetTotalData({},false).then(res=>{
					if(res.errCode==0){
						this.Circulation = res.data.Circulation
					}
				})
			},
			//根据地质查交易
			minerList() {
				getMaxAddrApi({},false).then((res) => {
					if (res.errCode == 0) {
						this.show = true;
						this.zancun = res.data;
						this.orderlist = res.data
					}
				});
			},
			// 占比
			persand(num){
				if(num){
					return ((num/parseFloat(this.Circulation))*100).toFixed(2)
				}
			},
			//交易详情
			goMinerTransfer(addr) {
				this.$router.push({
					name: "miner_ts",
					params: {
						addr: addr,
						page:1
					},
				});
			},

			fromHas(k, n) {
				if (k) {
					var a = k.split("");
					if (a[0] != "K") {
						return n == "from" ? "Coinbase" : "";
					} else {
						return k;
					}
				}
			},
			timeStr(n) {
				//return this.$moment(dateFormat(n)).startOf('second').fromNow();
				//return dateFormat(n)
			},
		},
		filters: {
			timeStr(n) {
				return dateFormat(n);
			},
			addrStr(str, n) {
				if (str) {
					return str.substring(0, n ? n : 14) + "...";
				} else {
					return str;
				}
			},
			tokenStr(script) {
				if (script) {
					var daibiarry = script.split(" ");
					if (daibiarry[2] * 1 >= 0) {
						daibiarry[2] = parseFloat(daibiarry[2]);
					} else {
						daibiarry[2] = parseFloat(daibiarry[3]);
					}
					var symbol = daibiarry[1].replace(/"/g, "");
					var num = daibiarry[2];
					let temp;
					if (symbol == "GPS") {
						temp = num / Math.pow(10, 11) + " " + symbol;
					} else {
						temp = num / Math.pow(10, 8) + " " + symbol;
					}
					return temp;
				} else {
					return script;
				}
			},
		},
	};
</script>

<style scoped="scoped" lang="scss">
	.miner-header{
		background: #FFFFFF;
	}
	.banner-img{
		img{
			width: 100%;
			display: block;
			margin: 0;
		}
	}
	.home-main {
		border: 1px solid #e7eaf3;
		margin-top: 20px;
		border-radius: 10px;
		box-sizing: border-box;
		box-shadow: 0px 6px 12px rgba(158, 158, 161, 0.12);
		background: #ffffff;

		.home-block-title {
			display: flex;
			padding-left: 20px;
			height: 50px;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			// flex-direction: column;
			p {
				color: #2a2c33;
				font-size: 16px;
				font-family: "syMediumFont";
				margin-right: 30px;
			}
		}

		.show-more {
			display: flex;
			justify-content: center !important;
			cursor: pointer;

			img {
				display: block;
				margin-top: 4px;
				margin-left: 2px;
			}

			span {
				font-size: 14px;
				color: #0022bb;
			}
		}

		.table-body {
			padding: 0 20px;
			height: 46px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: #7e8499;
			box-sizing: border-box;
			font-family: "numRegularFont";
		}
	}

	.p-active {
		font-weight: bolder;
	}
	.footer-page {
	  display: flex;
	  align-items: center;
	  justify-content: flex-end;
	  padding: 20px 0;
	}
</style>
