<template>
	<div class="home-echarts">
		<div class="e-tab">
			<span :class="{'etab':etabIndex==0}" @click="changeLine(0)">24H Latest Blocks</span>
		</div>
		<div class="echart-box">
			<div id="my-echart" ref="my-echart" style="width: 100%;height: 360px;"></div>
		</div>
	</div>
</template>

<script>
	import {Get24HData} from '@/api/home.js'
	var echarts = require('echarts');
	export default {
		data() {
			return {
				etabIndex: 0,
				eList:[
					[2428, 2728, 2662, 2828, 2728], //24H Vol
					[0, 0, 0, 0, 0], //24H Active Users
					[0, 0, 0, 0, 0]  //24H Txs
				],
				xData:['00:01', '06:00', '12:00', '18:00', '23:59']
			}
		},
		created() {
			this.setXdata()
		},
		mounted() {
			//this.get24HData()
			this.init()
		},
    watch:{
      $route(r){
        if(r.name=='Home'){
          this.init()
        }
      }
    },
		methods: {
			changeLine(index){
				this.etabIndex = index
				this.init()
			},
			get24HData(){
				Get24HData({}).then(res=>{
					if(res.code==0){
						this.$set(this.eList, 2,res.totaltxs_5)
						this.$set(this.eList, 1,res.activeaddr_5)
						this.$set(this.eList, 0,res.totalamount_5)
						this.init()
					}
				})
			},
			setXdata(){
				var hour = new Date().getHours()
				if(hour<24&&hour>=18){
					this.xData = ['18:00','00:00', '06:00', '12:00', '18:00']
				}else if(hour<18&&hour>=12){
					this.xData = ['12:00','18:00','00:00', '06:00', '12:00']
				}else if(hour<12&&hour>=6){
					this.xData = ['06:00','12:00','18:00','00:00', '06:00']
				}else if(hour<6&&hour>=0){
					this.xData = ['00:00','06:00','12:00','18:00','00:00']
				}
			},
			init() {
				var that = this
				var myChart = echarts.init(this.$refs['my-echart']);
				// 绘制图表
				myChart.setOption({
					legend: {
						show: false,
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'line',
							snap:false,
							label: {
								backgroundColor: '#6a7985'
							}
						}
					},
					grid: {
						top: 12 + '%',
						bottom: 5 + '%',
						left: 0 + '%',
						right: 5 + '%',
						height: 83 + '%',
						containLabel: true,
					},
					xAxis: {
						type: 'category',
						axisTick: {
							show: false,
						},
						boundaryGap: false,
						splitLine: {
							show: true,
							lineStyle: {
								width: 1,
								type: 'solid'
							}
						},
						axisLine: {
							lineStyle: {
								color: '#7E8499'
							}
						},
						axisLabel: {
							textStyle: {
								color: '#7E8499', //坐标值得具体的颜色
							}
						},
						data: that.xData
					},
					yAxis: {
						type: 'value',
						scale: true,
						axisTick: {
							show: false,
						},
						splitLine: {
							lineStyle: {
								type: 'dashed'
							}
						},
						axisLabel: {
							textStyle: {
								color: '#7E8499', //坐标值得具体的颜色
							},
							formatter:function(v){
								return (v/1000)>0?(v/1000) +'K':v
							}
						},
						axisLine: {
							onZero: false,
							lineStyle: {
								color: '#7E8499'
							}
						},
					},
					series: [{
						data: that.eList[that.etabIndex],
						type: 'line',
						smooth: true,
						showSymbol: false,
						lineStyle: {
							normal: {
								width: 2,
								color: '#121d37',
							}
						},
						areaStyle: {
							color: {
								type: 'linear',
								x: 0,
								y: 0,
								x2: 0,
								y2: 1,
								colorStops: [{
									offset: 0,
									color: 'rgba(179, 187, 213, 1)' // 0% 处的颜色
								}, {
									offset: 1,
									color: 'rgba(255, 255, 255, 0.3)' // 100% 处的颜色
								}],
								globalCoord: false // 缺省为 false
							}
						},
					}]
				});
				window.addEventListener('resize', myChart.resize)
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.home-echarts {
		padding: 25px 15px 15px 20px;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		height: inherit;

		.echart-box {
			flex: 1;
			padding-top: 32px;
			position: relative;
		}
	}

	#my-echart {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 555px;
		height: 231px;
	}

	.e-tab {
		display: flex;
		align-items: center;

		span {
			font-size: 14px;
			color: #7E8499;
			margin-right: 16px;
			cursor: pointer;
		}

		.etab {
			color: #2A2C33;
			font-weight: bold;
		}
	}
</style>
