import { render, staticRenderFns } from "./holdcoin.vue?vue&type=template&id=64155524&scoped=true&"
import script from "./holdcoin.vue?vue&type=script&lang=js&"
export * from "./holdcoin.vue?vue&type=script&lang=js&"
import style0 from "./holdcoin.vue?vue&type=style&index=0&id=64155524&scoped=scoped&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64155524",
  null
  
)

export default component.exports