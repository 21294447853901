<template>
	<div class="c-token">
		<el-dialog title="Regist Contract" custom-class="create-contract" :visible.sync="loginTokenShow" width="20%"
		 :close-on-click-modal="false" @closed="closed">
			<div class="c-token-item">
				<span class="c-token-lable">Email</span>
				<input type="text" placeholder="Enter your email" v-model="email" />
			</div>
			<div class="c-token-item">
				<span class="c-token-lable">Code</span>
				<div class="c-token-code">
					<input type="text" placeholder="Enter your email code" maxlength="6" v-model="code" />
					<span @click="disabled&&sendEmail()">{{btntxt}}</span>
				</div>

			</div>
			<div class="c-token-item">
				<span class="c-token-lable">Password</span>
				<input type="password" placeholder="Enter the password" v-model="pwd" />
			</div>
			<div class="c-token-item">
				<span class="c-token-lable">Confirm Password</span>
				<input type="password" placeholder="Enter the confirm password" v-model="rpwd" />
			</div>
			<div class="c-token-box">
				<span @click="regist()">Register</span>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { registContractApi,sendEmailApi } from '@/api/home.js'
	export default {
		data() {
			return {
				loginTokenShow: true,
				email: '',
				code: '',
				pwd: '',
				rpwd: '',
				disabled:true,
				time:60,
				timer:null,
				btntxt:'Send'
			}
		},
		methods: {
			regist() {
				var reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //正则表达式
				var pwdReg = new RegExp("[^0-9a-zA-z]")
				if (this.email == '') {
					this.$notify({
						title: 'warning',
						message: 'The mailbox cannot be empty',
						type: 'warning'
					});
					return
				}else if(!reg.test(this.email)){
					this.$notify({
						title: 'warning',
						message: 'Incorrect email format',
						type: 'warning'
					});
					return
				}
				if(this.code==''){
					this.$notify({
						title: 'warning',
						message: 'The code cannot be empty',
						type: 'warning'
					});
					return
				}
				if(!this.CheckPassWord(this.pwd)){
					this.$notify({
						title: 'warning',
						message: 'The password does not conform to the rules',
						type: 'warning'
					});
					return
				}
				if(this.rpwd!=this.pwd){
					this.$notify({
						title: 'warning',
						message: 'Inconsistent passwords',
						type: 'warning'
					});
					return
				}
				this.registContract()
			},
			closed() {
				this.$emit('tokenCB', {type: 0,d: ''})
			},
			registContract() {
				registContractApi({email:this.email,password:this.pwd,verifycode:this.code}).then(res => {
					if(res.code==0){
						this.$notify({
							title: 'success',
							type: 'success'
						});
						this.$emit('tokenCB', { type: 1, d: '' })
					}else{
						this.$notify.error({
							title: 'Error',
							message: res.message,
						});
					}
				})
			},
			sendEmail(){
				var reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //正则表达式
				if (this.email == '') {
					this.$notify({
						title: 'warning',
						message: 'The mailbox cannot be empty',
						type: 'warning'
					});
					return
				}else if(!reg.test(this.email)){
					this.$notify({
						title: 'warning',
						message: 'Incorrect email format',
						type: 'warning'
					});
					return
				}else{
					sendEmailApi({email:this.email}).then(res=>{
						if(res.code==0){
							this.timerOut()
							this.$notify({
								title: 'success',
								type: 'success'
							});
						}
					})
				}
			},
			//定时器
			timerOut() {
				var n = this.time
				this.disabled = false;
				this.timer = setInterval(() => {
					n--;
					this.btntxt = n + 's '
					if (n == 0) {
						clearInterval(this.timer)
						this.disabled = true;
						this.btntxt = 'Send'
						this.time = 60
					}
				}, 1000)
			},
			CheckPassWord(password) {//必须为字母加数字且长度不小于6位
			   var str = password;
			    if (str == null || str.length <6) {
			        return false;
			    }
			    var reg1 = new RegExp(/^[0-9A-Za-z]+$/);
			    if (!reg1.test(str)) {
			        return false;
			    }
			    var reg = new RegExp(/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/);
			    if (reg.test(str)) {
			        return true;
			    } else {
			        return false;
			    }
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.c-token-item {
		display: flex;
		flex-direction: column;
		// align-items: center;
		margin-bottom: 20px;

		.c-token-lable {
			font-size: 14px;
			margin-bottom: 8px;
			// text-align: right;
		}

		input {
			flex: 1;
			line-height: 100%;
			height: 100%;
			border: 1px solid #AFBACC;
			font-size: 14px;
			padding: 8px 12px;
			color: #545866;
			border-radius: 3px;

			&::-webkit-input-placeholder {
				color: #AFBACC;
			}

			&::-ms-input-placeholder {
				color: #AFBACC;
			}

			&::-moz-placeholder {
				color: #AFBACC;
			}

			&::-moz-placeholder {
				color: #AFBACC;
			}
		}
	}

	.c-token-code {
		display: flex;
		align-items: center;

		span {
			height: 34px;
			background: #1a89fa;
			display: inline-block;
			line-height: 34px;
			color: #FFFFFF;
			padding: 0 10px;
			border-radius: 3px;
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
			cursor: pointer;
		}

		input {
			width: 60% !important;
			border-right: none !important;
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}
	}

	.c-token-box {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-top: 20px;

		span:nth-child(1) {
			padding: 6px 0px;
			text-align: center;
			border-radius: 3px;
			border: 1px solid #1a89fa;
			cursor: pointer;
			color: #fff;
			background: #1a89fa;
			width: 100%;
		}
	}
</style>
