const config = {
  solidityVersion:'',
	// url:'https://www.kortho-test.com/api',//
	// url1:'https://www.kortho-test.com/server', //
	// url2:'https://www.kortho-test.com/contract', //
	// url3:'http://118.193.37.36:2926',
 //  minerUrl:'https://www.kortho-test.com'

	// url:'https://www.kortho.io/api',//
	// url1:'https://www.kortho.io/server', //
	// url2:'https://www.kortho.io/contract', //
	// url3:'http://118.193.37.36:6868',
	// minerUrl:'https://www.kortho.io/',

	url:'/api',//
	url1:'/server', //
	url2:'/contract', //
	url3:'/server',
	minerUrl:''
}

export default config
