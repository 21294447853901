import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		meta:{
			keepAlive: true
		},
		component: Home,
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 0)
			next()
		}
	},
	{
		path: '/Ts/:searchVal/:page', //交易列表
		name: 'Ts',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/transfer.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 1)
			next()
		}
	},
	{
		path: '/MoreTs', //交易列表
		name: 'MoreTs',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/MoreTransfer.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 1)
			next()
		}
	},
	{
		path: '/address/:searchVal', //交易列表
		redirect:'/Ts/:searchVal/:page'
	},{
		path: '/Ts20/:searchValue/:page', //交易列表
		name: 'Ts20',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/transfer20.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 1)
			next()
		}
	},
	{
		path: '/miner', //交易列表
		name: 'miner',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/miner.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 2)
			next()
		}
	},
	{
		path: '/holdcoin', //交易列表
		name: 'holdcoin',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/holdcoin.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 4)
			next()
		}
	},
	{
		path: '/miner_ts/:addr/:page', //交易列表
		name: 'miner_ts',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/miner_transfer.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 2)
			next()
		}
	},
	{
		path: '/verifyContract', //交易详情
		name: 'verifyContract',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/verifyContract.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 1)
			next()
		}
	},{
		path: '/policies', //交易详情
		name: 'policies',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/policies.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 1)
			next()
		}
	},{
		path: '/sourceCode', //交易详情
		name: 'sourceCode',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/sourceCode.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 1)
			next()
		}
	},
	{
		path: '/Td/:searchVal', //交易详情
		name: 'Td',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/transferDetail.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 1)
			next()
		}
	},{
		path: '/Td20/:searchVal', //交易详情
		name: 'Td20',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/transfer20Detail.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 1)
			next()
		}
	},
	{
		path: '/tx/:searchVal', //交易列表
		redirect:'/Td20'
	},
	{
		path: '/Block/:searchVal/:page', //区块列表
		name: 'Block',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/block.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 1)
			next()
		}
	},
	{
		path: '/MoreBlock', //区块列表
		name: 'MoreBlock',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/MoreBlock.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 1)
			next()
		}
	},
	{
		path: '/Bd', //区块详情
		name: 'Bd',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/blockDetail.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 1)
			next()
		}
	},
	{
		path: '/About', //关于我们
		name: 'About',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/about.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 5)
			next()
		}
	},
	{
		path: '/W', //钱包
		name: 'wallet',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/wallet.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 3)
			next()
		}
	},
	{
		path: '/Dapp', //Dapp
		name: 'dapp',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/dapp.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 4)
			next()
		}
	},
	{
		path: '/Od', //订单详情
		name: 'Od',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/orderDetail.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 1)
			next()
		}
	},
	{
		path: '/Cd', //代币详情
		name: 'Cd	',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/coinDetail.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 1)
			next()
		}
	},
	{
		path: '/404', //
		name: '404',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/404.vue')
	},
	{
		path: '/faq', //
		name: 'faq',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/faq.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 77)
			next()
		}
	},
	{
		path: '/blockchain-pay-detail', //
		name: 'blockchain-pay-detail',
		meta:{
			keepAlive: false
		},
		redirect:'/Td'
	},{
		path: '/Miner/:searchVal', //交易列表
		name: 'Miner',
		meta:{
			keepAlive: false
		},
		component: () => import('../views/miner_transfer.vue'),
		beforeEnter: (to, from, next) =>{
			store.commit('SET_NAVINDEX', 2)
			next()
		}
	},
]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
	mode: 'hash',//'history',
	base: process.env.BASE_URL,
	routes
})
router.beforeEach((to, from, next) => {
	if(!to.name){
		next('/')
	}else{
		next()
	}
})
export default router
