<template>
	<div class="c-token">
		<el-dialog title="Create Contract" custom-class="create-contract" :visible.sync="loginTokenShow" 
			width="30%" :close-on-click-modal="false" @closed="closed">
			<div class="c-token-item">
				<span class="c-token-lable">Address</span>
				<input type="text" placeholder="Enter your address" v-model="address"/>
			</div>
			<div class="c-token-item">
				<span class="c-token-lable">TokenName</span>
				<input type="text" placeholder="Enter your tokenname"  v-model="tokenname"/>
			</div>
			<div class="c-token-item">
				<span class="c-token-lable">Symbol</span>
				<input type="text" placeholder="Enter your symbol" v-model="symbol"/>
			</div>
			<div class="c-token-item">
				<span class="c-token-lable">Total</span>
				<input type="text" placeholder="Enter your total"  v-model="total"/>
			</div>
			<div class="c-token-item">
				<span class="c-token-lable">PrivateKey</span>
				<input type="text" placeholder="Enter your privateKey"  v-model="privateKey"/>
			</div>
			<div class="c-token-box">
				<span @click="Create()">Create</span>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { creatContractApi } from '@/api/home.js'
	export default {
		data(){
			return {
				loginTokenShow:true,
				address:'',
				tokenname:'',
				symbol:'',
				total:'',
				privateKey:''
			}
		},
		methods:{
			Create(){
				if(this.address==''){
					this.$notify({
						title: 'warning',
						message: 'The address cannot be empty',
						type: 'warning'
					});
					return
				}
				if(this.tokenname==''){
					this.$notify({
						title: 'warning',
						message: 'The tokenName cannot be empty',
						type: 'warning'
					});
					return
				}
				if(this.symbol==''){
					this.$notify({
						title: 'warning',
						message: 'The symbol cannot be empty',
						type: 'warning'
					});
					return
				}
				if(this.total==''){
					this.$notify({
						title: 'warning',
						message: 'The total cannot be empty',
						type: 'warning'
					});
					return
				}
				if(this.privateKey==''){
					this.$notify({
						title: 'warning',
						message: 'The privateKey cannot be empty',
						type: 'warning'
					});
					return
				}
				var params = {
					address:this.address,
					tokenName:this.tokenname,
					symbol:this.symbol,
					private:this.privateKey,
					total:this.total,
				}
				creatContractApi(params).then(res=>{
					if(res.code==0){
						this.$notify({
							title: 'success',
							message: res.message,
							type: 'success'
						});
						this.closed()
					}
				})
				//this.$emit('tokenCB',{type:2,d:''})
			},
			closed(){
				this.$emit('tokenCB',{type:0,d:''})
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.c-token-item{
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
		span{
			font-size: 14px;
			margin-bottom: 8px;
		}
		input{
			flex: 1;
			line-height: 100%;
			height: 100%;
			border: 1px solid #AFBACC;
			font-size: 14px;
			padding: 8px 12px;
			color: #545866;
			border-radius: 3px;
			&::-webkit-input-placeholder{
				color: #AFBACC;
			}
			&::-ms-input-placeholder{
				color: #AFBACC;
			}
			&::-moz-placeholder{
				color: #AFBACC;
			}
			&::-moz-placeholder{
				color: #AFBACC;
			}
		}
	}
	.c-token-box{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-top: 20px;
		span:nth-child(1){
			padding: 6px 0px;
			text-align: center;
			border-radius: 4px;
			border: 1px solid #1a89fa;
			cursor: pointer;
			color: #FFFFFF;
			background:#1a89fa;
			width: 100%;
		}
	}
</style>
